.home {
    margin: 0;
    padding: 0;
    background: var(--linear-black) !important;
    font-family: var(--font-MPLUS);
    
    z-index: -10;
    overflow: visible;

    .container {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .content {
            display: flex;
            flex-direction: column;
            margin: 9rem 4rem 9rem 3rem;
            justify-content: space-evenly;
            align-items: flex-start;
            .title {
                color: var(--white);
                font-size: 50px;
                letter-spacing: 0.1rem;
                text-align: left;
            }
            .description {
                
                color: var(--white);
                font-size: 18px;
                text-align: left;
            }
            .button-box {
                display: block;
            }
            button {
                color: var(--white);
                background-color: var(--blue);
                font-weight: bold;
                padding: 1rem 2rem; /* Adjust padding */
                border-radius: 2rem;
                border: solid 3px transparent;
            }
        }
    }

    .image-container {
        text-align: center;
        position: relative;
        z-index: 10;

        .desktop-photo {
            max-width: 50vw;
            max-height: auto; 
            width: auto;
            height: auto;
        }

        .mobile-photo {
            display: none;
        }
    }
}

@media (max-width: 768px) {

    
    .home {

        padding: 0;
        margin: 0;

        height: 100vh;

        .container {

            display: flex;
            flex-direction: row;

            padding: 0;
            margin: 0;

            

            .content {
                

                display: flex;
                flex-direction: column;

                z-index: 10;
                justify-content: right;
                align-items: end;

                gap: 2rem;

                padding: 1.11rem;
                margin: 1rem;
                

                .title {
                    margin: 0;
                    padding: 0;
                    font-weight: bold;
                    font-size: 2rem;
                    letter-spacing: 0.4rem;
                    text-align: left;
                    font-weight: 900;
                }
                .description {
                    margin: 0;
                    padding: 0;
                    font-weight: 200;
                    font-size: 19px;
                    text-align: left;
                }
                .button-box {
                    display: none;
                }
                button {
                    color: var(--white);
                    background-color: var(--blue);
                    font-weight: bold;
                    padding: 1rem 2rem; /* Adjust padding */
                    border-radius: 2rem;
                    border: solid 3px transparent;
                }
            }
        }

        .image-container {
            
            text-align: center;
            position: relative;
            

            .desktop-photo  {
                display: none;  
            }

            .mobile-photo  {
                
                display: block !important;         
                position: absolute !important;      
                top: 27vh;                
                left: 10vw;                 
                width: 21rem;            
                height: auto;            
                z-index: -1;
                filter: brightness(0.64) contrast(1.17);
                transform: rotate(-1.4deg);
        
            }
            

        }
    
    } 
    
}