.masthead {
    height: 3rem;
    background: var(--fade-black);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    color: ghostwhite;
    font-size: small;

    .stay-hydrated img {
        color: #ffffff;
        max-width: 20vh;
    }

}

@media (max-width: 768px) {
    .masthead {
        font-size: 0.555rem;
    }

    .stay-hydrated img {
        color: #ffffff;
        max-width: 60vw;
        margin-left: 0vw;
    }

}


