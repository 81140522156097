.magsafe {

    background-color: black;
    position: relative;
    overflow: hidden;
}
.magsafe-content {
    display: flex;
    color: white;
    font-weight: 100;
    font-size: 1.5rem;
    padding-left: 7rem;
    justify-content: center;
    align-items: center;
    height: 100%;
}


.magsafe-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.magsafe-img img {
    max-width: 61vw;
}

@media (max-width: 768px) { 
    .magsafe-content { 
        font-size: 1rem;
        padding-left: 5.5rem;
        text-align: right;
        padding-right: 1rem;
    }


}