@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
    --blue: #0000FF;
    --grey: #D0D0D0;
    --white: #f5f2f2;
    --lightgrey: #F1F1F1;
    --black: #000000;
    --fade-black: #171717;
    --accent-color1: yellow;
    --accent-color2: #61afdd;
    --linear-blue: linear-gradient(65deg, #0000FF, #000000);
    --vertical-blue: linear-gradient(0deg, #0000FF, #000000, #000000);
    --linear-black: linear-gradient(180deg, #11121a, #000000);
    --background: #F5F8FA;
    --font-Archivo: "Archivo Black", sans-serif;
    --font-IBM: "IBM Plex Mono", monospace;
    --font-MPLUS: "M PLUS 1", sans-serif, <weight>, normal;
    --font-Montserrat: "Montserrat", sans-serif, <weight>, normal;
    --font-Geologica: "Geologica", sans-serif, <weight>, normal;
}


* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}




.app-container {
    font-family: var(--font-IBM);
    
    @media screen and (min-width:280px) and (max-width:1080px) {
        padding: 0;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    
}

::-webkit-scrollbar {
    display: none;
}
