.footer {

    background: var(--blue);
    font-family: var(--font-MPLUS);
    color: var(--white);
    height: 20vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .branding {
        font-weight: 100;
        text-align: left;
        img {
            width: 14vw;
        }
    }
    .stay-hydrated img {
        color: #ffffff;
        max-width: 20vw;
        margin-right: 1rem;
    }
    .social {
        display: flex;
        justify-content: center; /* Centers the icons horizontally */
        align-items: center;
        margin-right: 1.6rem;
        .social-icons {
            .social-insta {
                font-size: 2.1rem;
                margin-bottom: 0.1rem;
                margin-right: 2rem;
            }
            .social-mail {
                font-size: 3rem;
                margin-bottom: 0.1rem;
                margin-right: 2rem;
            }
            .social-phone {
                font-size: 2.4rem;
            }
            
        }

        a {
            color: inherit; /* Inherits the color of the parent element */
            text-decoration: none; /* Removes the underline */
        }
          
    }
}

@media (max-width: 768px) {
    .footer {
        height: 35vh;

        display: grid;
        grid-template-columns: auto;
        align-items: center;

        .branding {
            font-weight: 200;
            text-align: left;
            img {
                padding-top: 3vh;
                width: 70vw;
            }
        }
        .stay-hydrated img {
            color: #ffffff;
            max-width: 60vw;
            margin-left: 5vw;
        }
        .social {
            margin-right: 0rem;
        }
        
        .social-icons {

            .social-insta {
                font-size: 4.1rem;
                margin-bottom: 0.1rem;
                margin-right: 0rem;
            }
            .social-mail {
                font-size: 5rem;
                margin-bottom: 1.7rem !important;
                margin-right: 0rem;
            }
            .social-phone {
                font-size: 4.3rem;
            }
            
        }

    }
    
}