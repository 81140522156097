.product {
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-family: var(--font-MPLUS);
    background-color: var(--lightgrey);

    .product-photo img {
        padding: 0rem 0rem 0rem 6rem;
        max-width: 50vw;
        margin-left: 6rem;

    }

    .brand img {
      margin-left: -8px;
      max-height: 5vh;
      
    }
    .trademark {
      font-size: 10px;
      margin-bottom: 28px;
    }

    .payment-info {

        display: flex;
        flex-direction: column;
        align-items: left;
        padding: 4rem;
        color: var(--black);
        

        .title {
            font-weight: bold;
            font-size: 29px;
            letter-spacing: 0.4rem;
        }
        .sub-title {
            font-weight: 200;
            font-size: 19px;
            text-align: left;
            padding-top: 1rem;
            max-width: 20rem; /* Set the desired width here */
            word-wrap: break-word; /* Ensures long words break if necessary */
        }
          
        
    
        .compatablity {
            font-size: 15px;
            font-weight: 200;
            padding: 0.5rem;

        }
        .disclaimer{
            font-size: 13px;
            font-weight: 100;
            padding-left: 4.5rem;
        }

    .color-selector-container {

        padding:  4rem 0rem 2rem 0rem;

        .select-title {
            padding: 0rem 0rem 0.5rem 0rem;
            font-weight: 600;
            font-size: 17px;
        }

        .color-boxes {
            padding: 0rem 0rem 2rem 1rem;
            display: flex;
            gap: 1.2rem;
            margin-bottom: 20px;
            
        }
      
        .color-box {
          width: 33px;
          height: 33px;
          border: 2px solid transparent;
          cursor: pointer;
          transition: border 0.3s;
      
          &.selected {
            border-color: #0000FF;
          }
        }
      
        .color-name {
            font-weight: 500;
            font-size: 17px;
            margin-bottom: 20px;
          
            display: flex; /* Flexbox for horizontal alignment */
            align-items: center; /* Vertically center if heights are different */
          
            .cost {
              font-weight: 200;
              font-size: 14px;
              margin-left: 10px; /* Adds space between the name and cost */
            }
          }
          
        
        .buy-button {
            padding: 1rem 9rem;
            background-color: var(--blue);
            color: white;
            text-decoration: none;
            border-radius: 2rem;
            cursor: pointer;
            border: solid 3px transparent;
            transition: background-color 0.3s;
      
            &:hover {
                background-color: #195aff;
            }
      
          &.disabled {
            pointer-events: none;
            background-color: #9a9ebc;
          }
        }
      }
    }

    // Dropdown.scss
.dropdown-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding-bottom: 2rem;
  }
  
  .dropdown-label {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 600;
    color: #333;
  }
  
  .dropdown-select {
    width: 200px;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
    transition: border-color 0.2s;
  
    &:focus {
      border-color: #0000ff; /* Blue border when focused */
      outline: none;
    }
  
    option {
      font-size: 16px;
    }
  }
  
    
}

@media (max-width: 768px) {
    .product {

        overflow: hidden;
        grid-template-columns: 1fr;

        .product-photo img {
            padding: 1rem 0rem 0rem 1rem;
            max-width: 100vw;
            margin-left: 0rem;
        }
        .payment-info {

            display: flex;
            flex-direction: column;
            align-items: left;
            padding: 8.8vw;
            color: var(--black);
           
        }
        .trademark {
          font-size: 10px;
          margin-bottom: 12px;
        }
    }
    
}