.design {

    background-color: black;
    position: relative;
}

.design-text {
    color: white;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 100;
}

.design-container {
    display: grid;
    grid-template-columns: 0.33fr 0.33fr 0.333fr;
}

.design-img img {
    max-width: 33vw;
}


@media (max-width: 768px) { 

    .design-text {
      
        font-size: 1.2rem;
        font-weight: 300;
    }
}
