nav {
    background-color: var(--blue);
    display: flex;
    justify-content: space-between;
    align-items: left;
    padding: 0.8rem;
    

    .brand-container {
        .brand {
            img {
                width: 14vw;
            }
        }
        .toggle-container {
            display: none;
        }
    }
    .links-container {
        .links{
            list-style-type: none;
            display: flex;
            gap: 4rem;
            
            li {
                a {
                    color: aqua;
                    text-decoration: none;

                }
                &:last-of-type {
                    color: yellow;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    nav {
        display: flex;
        align-items: left;
        flex-direction: column;
        padding: 2rem 0rem 2rem 2rem;
    }
    .brand img {
        width: 9rem !important;
        object-fit: contain;
    }
}
