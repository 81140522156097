.steps {
    background: var(--linear-blue);
    position: relative;
    top: 0rem;
  
    font-family: var(--font-MPLUS);

    .container {
      display: grid;
      grid-template-columns: 1fr 1fr; /* Two equal-width columns */
      padding: 0rem;
      height: 100vh; /* Full height of the viewport */
  
      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between; /* Evenly space steps from top to bottom */
        padding: 6rem 0rem 6rem 9rem;
  
        .step {
          display: flex;
          flex-direction: row;
          gap: 3rem;
          align-items: center; /* Align icon and text vertically */
        
          .hydroFlask-Icon img {
                z-index: 10;
                height: 19vh;
                object-fit: contain;
            }
            .app-store-logo, .chart-icon {
                color: white;
                font-size: 15vh;
            }
            .description {
                color: var(--white);
                font-size: 23px;
                padding: 0; /* Remove padding around text */
          }
        }
      }
    }
  
    .iphone-image-container {
      z-index: 10;
      height: 100vh; /* Make the iPhone image fill the right side */
      display: flex;
      justify-content: center;
      align-items: center;
      
  
      .iphone-image img {
        max-height: 100%; /* Adjust to slightly fill the height of the container */
        max-width: 100%; /* Make sure it doesn't exceed the container width */
        object-fit: contain;
      }
    }
  }
  

  /* Mobile support */
@media (max-width: 768px) {
    .steps {
      background: var(--vertical-blue);

      .container {
        grid-template-columns: 1fr;
        .content {
          .step {
            flex-direction: row; /* Stack icon and text vertically */
            gap: 2rem;
  
            .hydroFlask-Icon img {
                height: 12vh; /* Smaller icons for mobile */
            }
  
            .app-store-logo, .chart-icon {
                font-size: 10vh; /* Adjust icon size for mobile */
            }
  
            .description {
              font-weight: 200;
                font-size: 12px; 
                text-align: right;
            }
          }
        }
      }
    
      .iphone-image-container {
        width: 50vh; /* Reduce height for mobile */
  
        .iphone-image img {
            max-width: 100vw;
            max-height: 100vh;
        }
      }
    }
  }
  
  @media (max-width: 480px) {
    .steps {
      .container {
        .content {
          padding: 3rem;
          
          .step {
            gap: 0.5rem;
  
            .hydroFlask-Icon img {
                height: 6vh;
            }
  
            .app-store-logo, .chart-icon {
                font-size: 5vh;
            }
  
            .description {
                margin-top: -0.5rem;
                font-size: 15px;
                padding-left: 3rem;
                padding-right: 0rem;
                text-align: left;
            }
          }
        }
      }
    
      .iphone-image-container {
        height: 50vh;
        
        .iphone-image img {
          max-height: 90vh;
        }
      }
    }
  }