.testflight {
    background-color: black;
    display: flex;
    justify-content: center; 
    align-items: center; 
    height: 100vh;  

    .testflight-container {
      display: grid;
      grid-template-columns: 1fr 1fr; 
      align-items: center; 
      padding-left: 7rem;
    }
  
    .testflight-content {
      color: white;
      font-weight: 100;
      font-size: 1.3rem;
      text-align: center; 

    }
  
    .testflight-button-box {

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; 
    }
  
    .testflight-header {
        padding: 2rem;
        color: white;
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }
  
    .button {
        color: var(--white);
        background-color: var(--blue);
        font-weight: bold;
        padding: 1rem 2rem;
        border-radius: 2rem;
        border: solid 3px transparent;
        margin-top: 1rem;
    }
  
    .testflight-img img {
      padding: 9rem;
      max-width: 66vw;
    }
  }
  


@media (max-width: 768px) { 

    .testflight {
        background-color: black;
        display: flex;
        justify-content: center; 
        align-items: center; 
        height: 100vh;  
        
        .testflight-content {
            color: white;
            font-weight: 100;
            font-size: 1.2rem;
            text-align: center; 
            padding: 1.5rem;
      
        }

        .testflight-container {
            display: grid;
            grid-template-columns: 1fr !important; 
            align-items: left; 
            padding-left: 0rem;
        }
        .button {
            margin-top: 0rem;
            margin-bottom: 1rem;
        }
        .testflight-img img {
            padding: 2rem;
            max-width: 100%;
        }
    }
  }